<template>
    <div class="bg-form">
        <div class="container mb-5">
            <div class="sub-container">
                <div class="head-form mb-4">
                    <div class="top-title">
                        <span>Booking for</span>
                        <h4>{{ category }}</h4>
                    </div>
                    <div class="bottom-info row">
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Details</span>
                        </div>
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Assessment</span>
                        </div>
                        <div class="col-4 text-center text-not-yet">
                            <span>Summary</span>
                        </div>
                    </div>
                </div>
                <div class="cards">
                    <div v-if="category != 'Confinement'" class="card">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.875 13.75H3.125C2.62772 13.75 2.15081 13.5525 1.79917 13.2008C1.44754 12.8492 1.25 12.3723 1.25 11.875V1.875C1.25 1.70924 1.31585 1.55027 1.43306 1.43306C1.55027 1.31585 1.70924 1.25 1.875 1.25H10.625C10.7908 1.25 10.9497 1.31585 11.0669 1.43306C11.1842 1.55027 11.25 1.70924 11.25 1.875V9.375H13.75V11.875C13.75 12.3723 13.5525 12.8492 13.2008 13.2008C12.8492 13.5525 12.3723 13.75 11.875 13.75ZM11.25 10.625V11.875C11.25 12.0408 11.3158 12.1997 11.4331 12.3169C11.5503 12.4342 11.7092 12.5 11.875 12.5C12.0408 12.5 12.1997 12.4342 12.3169 12.3169C12.4342 12.1997 12.5 12.0408 12.5 11.875V10.625H11.25ZM10 12.5V2.5H2.5V11.875C2.5 12.0408 2.56585 12.1997 2.68306 12.3169C2.80027 12.4342 2.95924 12.5 3.125 12.5H10ZM3.75 4.375H8.75V5.625H3.75V4.375ZM3.75 6.875H8.75V8.125H3.75V6.875ZM3.75 9.375H6.875V10.625H3.75V9.375Z" fill="#2C3A4B"/>
                                </svg>
                                Type of Services Required
                            </span>
                        </div>
                        <div class="card-body" v-if="bookingServices">
                            <div class="item" :class="{ 'no-bottom-border' : bookingServices.length == 1 }" v-for="(item, ind) in bookingServices" :key="item.id">
                                <label class="custom-checkbox">
                                    {{ item.service }}
                                    <small v-if="item.description && item.description != ''">({{ item.description }})</small>
                                    <input type="checkbox" v-model="type_of_services[ind].is_tick">
                                    <span class="checkmark"></span>
                                </label>
                                <input v-if="item.is_note_required && type_of_services[ind].is_tick" type="text" class="mt-3 form-control" v-model="type_of_services[ind].service_notes" placeholder="Describe your desire service">
                            </div>
                        </div>
                    </div>
                    <div v-if="category != 'Confinement'" class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.50033 15.5837C4.5882 15.5837 1.41699 12.4125 1.41699 8.50033C1.41699 4.5882 4.5882 1.41699 8.50033 1.41699C12.4125 1.41699 15.5837 4.5882 15.5837 8.50033C15.5837 12.4125 12.4125 15.5837 8.50033 15.5837ZM7.79199 7.79199H4.95866V9.20866H7.79199V12.042H9.20866V9.20866H12.042V7.79199H9.20866V4.95866H7.79199V7.79199Z" fill="#2C3A4B"/>
                                </svg>
                                Additional Treatment Required
                            </span>
                            <small>Optional & Chargeable</small>
                        </div>
                        <div class="card-body" v-if="bookingAdditionalTreatments">
                            <div class="item" :class="{ 'no-bottom-border' : bookingAdditionalTreatments.length == 1 }" v-for="(item, ind) in bookingAdditionalTreatments" :key="item.id">
                                <label class="custom-checkbox">
                                    {{ item.treatment }}
                                    <small v-if="item.description && item.description != ''">({{ item.description }})</small>
                                    <input type="checkbox" v-model="additional_treatments[ind].is_tick">
                                    <span class="checkmark"></span>
                                </label>
                                <input v-if="item.is_note_required && additional_treatments[ind].is_tick" type="text" class="mt-3 form-control" v-model="additional_treatments[ind].treatment_notes" placeholder="Describe your desire service">
                            </div>
                        </div>
                    </div>
                    <div v-if="category != 'Confinement'" class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.5 6.75H14.9535L15.3285 5.25H6V3.75H16.29C16.404 3.75 16.5165 3.776 16.619 3.826C16.7214 3.87601 16.8111 3.94871 16.8813 4.03859C16.9514 4.12847 17.0001 4.23315 17.0237 4.34468C17.0473 4.45622 17.0452 4.57166 17.0175 4.68225L15.1425 12.1823C15.1019 12.3444 15.0082 12.4884 14.8764 12.5913C14.7446 12.6941 14.5822 12.75 14.415 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12V3H1.5V1.5H3.75C3.94891 1.5 4.13968 1.57902 4.28033 1.71967C4.42098 1.86032 4.5 2.05109 4.5 2.25V6.75ZM4.5 17.25C4.10217 17.25 3.72064 17.092 3.43934 16.8107C3.15804 16.5294 3 16.1478 3 15.75C3 15.3522 3.15804 14.9706 3.43934 14.6893C3.72064 14.408 4.10217 14.25 4.5 14.25C4.89782 14.25 5.27936 14.408 5.56066 14.6893C5.84196 14.9706 6 15.3522 6 15.75C6 16.1478 5.84196 16.5294 5.56066 16.8107C5.27936 17.092 4.89782 17.25 4.5 17.25ZM13.5 17.25C13.1022 17.25 12.7206 17.092 12.4393 16.8107C12.158 16.5294 12 16.1478 12 15.75C12 15.3522 12.158 14.9706 12.4393 14.6893C12.7206 14.408 13.1022 14.25 13.5 14.25C13.8978 14.25 14.2794 14.408 14.5607 14.6893C14.842 14.9706 15 15.3522 15 15.75C15 16.1478 14.842 16.5294 14.5607 16.8107C14.2794 17.092 13.8978 17.25 13.5 17.25Z" fill="#2C3A4B"/>
                                </svg>
                                Medical supplies/Medication required
                            </span>
                            <small>Optional</small>
                        </div>
                        <div class="card-body">
                            <div v-if="medical_supplies && medical_supplies.length > 0" class="table-responsive mb-3">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" width="10%">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col" width="20%">Price</th>
                                            <th scope="col" width="15%">Quantity</th>
                                            <th scope="col" width="10%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product, ind) in medical_supplies" :key="product.id">
                                            <th scope="row">{{ind+1}}</th>
                                            <td>{{product.item.title}}</td>
                                            <td>RM {{ (product.item.variants[0].price).toFixed(2) }}</td>
                                            <td><input type="number" class="form-control" v-model.number="product.quantity" @change="updateMedicalSuppliesItemsCount()"></td>
                                            <td class="text-center"><button class="btn" @click="removeMedicalSuppliesItem(product)"><i class="fas fa-trash-alt"></i></button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-add" data-toggle="modal" data-target="#medicalSuppliesModal" @click="refetchProducts()"><i class="fas fa-plus"></i> Add Item From Store</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="category != 'Confinement'" class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0007 22.1663C5.9379 22.1663 1.83398 18.0624 1.83398 12.9997C1.83398 7.93692 5.9379 3.83301 11.0007 3.83301C16.0634 3.83301 20.1673 7.93692 20.1673 12.9997C20.1673 18.0624 16.0634 22.1663 11.0007 22.1663ZM10.084 12.083V17.583H11.9173V12.083H10.084ZM10.084 8.41634V10.2497H11.9173V8.41634H10.084Z" fill="#2C3A4B"/>
                                </svg>
                                Any preference for provider?
                            </span>
                            <small>Provider preferences</small>
                        </div>
                        <div class="card-body">
                            <div class="text-center">
                                <toggle-button v-model="hasRequirement"
                                :labels="{checked: 'Yes', unchecked: 'No'}" class="" :width="50"
                                :color="{checked: '#19AB4F', unchecked: '#fb122a'}"/>
                            </div>
                            <div class="row mt-3" v-if="hasRequirement">
                                <div class="offset-2 col-4">
                                    <label for="">Gender</label>
                                    <select class="custom-select" v-model="gender_requirement">
                                        <option v-if="!gender_requirement" :value="null" selected disabled>Choose gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <label for="">Race</label>
                                    <select class="custom-select" v-model="race_requirement">
                                        <option v-if="!race_requirement" :value="null" selected disabled>Choose race</option>
                                        <option value="Malay">Malay</option>
                                        <option value="Chinese">Chinese</option>
                                        <option value="Indian">Indian</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="category != 'Confinement' && (!listFixedPackagePrice || listFixedPackagePrice.length == 0)" class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 19.25H3.5C3.26794 19.25 3.04538 19.1578 2.88128 18.9937C2.71719 18.8296 2.625 18.6071 2.625 18.375V2.625C2.625 2.39294 2.71719 2.17038 2.88128 2.00628C3.04538 1.84219 3.26794 1.75 3.5 1.75H17.5C17.7321 1.75 17.9546 1.84219 18.1187 2.00628C18.2828 2.17038 18.375 2.39294 18.375 2.625V18.375C18.375 18.6071 18.2828 18.8296 18.1187 18.9937C17.9546 19.1578 17.7321 19.25 17.5 19.25ZM16.625 17.5V3.5H4.375V17.5H16.625ZM7 6.125H14V7.875H7V6.125ZM7 9.625H14V11.375H7V9.625ZM7 13.125H14V14.875H7V13.125Z" fill="#2C3A4B"/>
                                </svg>
                                Assessment
                            </span>
                            <small>Optional</small>
                        </div>
                        <div class="card-body" v-if="bookingAssessments">
                            <div class="item" v-for="(item, ind) in bookingAssessments" :key="item.id">
                                <div class="row">
                                    <div class="col-8">
                                        <label for="" style="width:100%">
                                            {{ ind+1 }}. {{ item.question }}
                                            <small v-if="item.description && item.description != ''">({{ item.description }})</small>
                                        </label>
                                    </div>
                                    <div class="col-4 pt-2">
                                        <div class="float-right">
                                            <toggle-button v-model="assessments[ind].is_tick"
                                            :labels="{checked: 'Yes', unchecked: 'No'}" class="" :width="50"
                                            :color="{checked: '#19AB4F', unchecked: '#fb122a'}"/>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="assessments[ind].is_tick != item.is_ticked_by_default" class="row mt-2 mb-2 pl-2 pr-2">
                                    <input type="text" class="form-control" placeholder="Explaination" v-model="assessments[ind].explaination">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="category == 'Confinement'" class="card">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.875 13.75H3.125C2.62772 13.75 2.15081 13.5525 1.79917 13.2008C1.44754 12.8492 1.25 12.3723 1.25 11.875V1.875C1.25 1.70924 1.31585 1.55027 1.43306 1.43306C1.55027 1.31585 1.70924 1.25 1.875 1.25H10.625C10.7908 1.25 10.9497 1.31585 11.0669 1.43306C11.1842 1.55027 11.25 1.70924 11.25 1.875V9.375H13.75V11.875C13.75 12.3723 13.5525 12.8492 13.2008 13.2008C12.8492 13.5525 12.3723 13.75 11.875 13.75ZM11.25 10.625V11.875C11.25 12.0408 11.3158 12.1997 11.4331 12.3169C11.5503 12.4342 11.7092 12.5 11.875 12.5C12.0408 12.5 12.1997 12.4342 12.3169 12.3169C12.4342 12.1997 12.5 12.0408 12.5 11.875V10.625H11.25ZM10 12.5V2.5H2.5V11.875C2.5 12.0408 2.56585 12.1997 2.68306 12.3169C2.80027 12.4342 2.95924 12.5 3.125 12.5H10ZM3.75 4.375H8.75V5.625H3.75V4.375ZM3.75 6.875H8.75V8.125H3.75V6.875ZM3.75 9.375H6.875V10.625H3.75V9.375Z" fill="#2C3A4B"/>
                                </svg>
                                Confinement Services
                            </span>
                        </div>
                        <div class="card-body" v-if="confinementServices">
                            <div class="item" :class="{ 'no-bottom-border' : confinementServices.length == 1 }" v-for="(item, ind) in confinementServices" :key="item.id">
                                <label class="custom-checkbox">
                                    {{ item.package_name }}
                                    <span class="badge badge-pill badge-info font-weight-normal ml-2">
                                        {{ item.package_type == 'daily_visit' ? 'Daily Visit' : 'Stay In' }}
                                    </span>
                                    <div 
                                        v-if="item.package_detail && item.package_detail != ''"
                                        class="mt-2">
                                        <small v-html="item.package_detail"></small>
                                    </div>
                                    <input type="checkbox" @change="selectConfinementService(item)">
                                    <span class="checkmark"></span>
                                </label>
                                <!-- <input v-if="item.is_note_required && type_of_services[ind].is_tick" type="text" class="mt-3 form-control" v-model="type_of_services[ind].service_notes" placeholder="Describe your desire service"> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="category == 'Confinement'" class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.875 13.75H3.125C2.62772 13.75 2.15081 13.5525 1.79917 13.2008C1.44754 12.8492 1.25 12.3723 1.25 11.875V1.875C1.25 1.70924 1.31585 1.55027 1.43306 1.43306C1.55027 1.31585 1.70924 1.25 1.875 1.25H10.625C10.7908 1.25 10.9497 1.31585 11.0669 1.43306C11.1842 1.55027 11.25 1.70924 11.25 1.875V9.375H13.75V11.875C13.75 12.3723 13.5525 12.8492 13.2008 13.2008C12.8492 13.5525 12.3723 13.75 11.875 13.75ZM11.25 10.625V11.875C11.25 12.0408 11.3158 12.1997 11.4331 12.3169C11.5503 12.4342 11.7092 12.5 11.875 12.5C12.0408 12.5 12.1997 12.4342 12.3169 12.3169C12.4342 12.1997 12.5 12.0408 12.5 11.875V10.625H11.25ZM10 12.5V2.5H2.5V11.875C2.5 12.0408 2.56585 12.1997 2.68306 12.3169C2.80027 12.4342 2.95924 12.5 3.125 12.5H10ZM3.75 4.375H8.75V5.625H3.75V4.375ZM3.75 6.875H8.75V8.125H3.75V6.875ZM3.75 9.375H6.875V10.625H3.75V9.375Z" fill="#2C3A4B"/>
                                </svg>
                                Confinement Add-ons
                            </span>
                        </div>
                        <div class="card-body" v-if="confinementAddons">
                            <div class="item" :class="{ 'no-bottom-border' : confinementAddons.length == 1 }" v-for="(item, ind) in confinementAddons" :key="item.id">
                                <div v-if="selected_confinement_addons.find(i => i._id == item._id)" class="position-relative d-none d-xl-block">
                                    <div class="custom-input-quantity">
                                        <label for="">Quantity : </label>
                                        <input class="form-control form-control-sm" type="text" @change="changeConfinementAddonQuantity($event, item)" :value="1">
                                    </div>
                                </div>
                                <label class="custom-checkbox">
                                    {{ item.package_name }}
                                    <div 
                                        v-if="item.package_detail && item.package_detail != ''"
                                        class="mt-2">
                                        <small v-html="item.package_detail"></small>
                                    </div>
                                    <input type="checkbox" @change="selectConfinementAddon(item)">
                                    <span class="checkmark"></span>
                                </label>
                                <div v-if="selected_confinement_addons.find(i => i._id == item._id)" class="d-xl-none">
                                    <div class="custom-input-quantity" style="position:initial">
                                        <label for="">Quantity : </label>
                                        <input class="form-control form-control-sm" type="text" @change="changeConfinementAddonQuantity($event, item)" :value="1">
                                    </div>
                                </div>
                                <!-- <input v-if="item.is_note_required && type_of_services[ind].is_tick" type="text" class="mt-3 form-control" v-model="type_of_services[ind].service_notes" placeholder="Describe your desire service"> -->
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-title text-center mt-1">
                            <span>
                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.167 1.49967H17.5003C17.7213 1.49967 17.9333 1.58747 18.0896 1.74375C18.2459 1.90003 18.3337 2.11199 18.3337 2.33301V15.6663C18.3337 15.8874 18.2459 16.0993 18.0896 16.2556C17.9333 16.4119 17.7213 16.4997 17.5003 16.4997H2.50033C2.27931 16.4997 2.06735 16.4119 1.91107 16.2556C1.75479 16.0993 1.66699 15.8874 1.66699 15.6663V2.33301C1.66699 2.11199 1.75479 1.90003 1.91107 1.74375C2.06735 1.58747 2.27931 1.49967 2.50033 1.49967H5.83366V-0.166992H7.50033V1.49967H12.5003V-0.166992H14.167V1.49967ZM3.33366 6.49967V14.833H16.667V6.49967H3.33366ZM5.00033 9.83301H9.16699V13.1663H5.00033V9.83301Z" fill="#394452"/>
                                </svg>
                                Schedule
                            </span>
                        </div>
                        <div class="card-body">
                            <div v-if="schedule_mode == 'default'">
                                <!-- <div class="mt-2 text-center option-buttons">
                                    <button class="btn btn-left btn-active">Same Day</button>
                                    <button class="btn btn-right">Custom Date</button>
                                </div> -->
                                <div v-if="!listFixedPackagePrice || listFixedPackagePrice.length == 0">
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <label v-if="category == 'Confinement'" for="">Select Start Date</label>
                                            <label v-else for="">Select Date</label>
                                            <datepicker2 type="date" format="YYYY-MM-DD" value-type="format" input-class="form-control datepicker" placeholder="Select Date" v-model="scheduledate" :disabled-date="disabledDateValidation"></datepicker2>
                                        </div>
                                    </div>
                                    <div v-if="!schedule_date_only" class="row mt-3">
                                        <div class="col-6">
                                            <label for="">From</label>
                                            <datepicker2 type="time" format="hh:mm a" value-type="format" :time-picker-options="{ start: '08:00', step: '01:00', end: '22:00' }" input-class="form-control datepicker" placeholder="Select Time" v-model="scheduleTimeStart"></datepicker2>
                                        </div>
                                        <div class="col-6">
                                            <label for="">To</label>
                                            <datepicker2 type="time" format="hh:mm a" value-type="format" :time-picker-options="{ start: '08:00', step: '01:00', end: '22:00' }" input-class="form-control datepicker" placeholder="Select Time" v-model="scheduleTimeEnd"></datepicker2>
                                        </div>
                                    </div>
                                    <div class="row mt-5" v-if="category != 'Confinement'">
                                        <div class="col-12 text-center">
                                            <span class="text-action-primary" @click="schedule_mode = 'custom'">Click here to customize your schedule</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="row mt-3">
                                        <div class="col-12 text-center">
                                            <label for="" class="label-input-required">Select Session</label>
                                            <select class="custom-select" v-model="selectedFixedPackagePrice">
                                                <option v-if="!selectedFixedPackagePrice" :value="null" selected disabled>Choose session</option>
                                                <option v-for="packageItem in listFixedPackagePrice" :key="packageItem.id" :value="packageItem">{{ packageItem.name }} (RM {{(packageItem.price).toFixed(2)}})</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-6">
                                            <label for="">Select Date</label>
                                            <datepicker2 type="date" format="YYYY-MM-DD" value-type="format" input-class="form-control datepicker" placeholder="Select Date" v-model="scheduledate" :disabled-date="disabledDateValidation"></datepicker2>
                                        </div>
                                        <div class="col-6">
                                            <label for="">Select Time</label>
                                            <datepicker2 type="time" format="hh:mm a" value-type="format" :time-picker-options="{ start: '08:00', step: '01:00', end: '22:00' }" input-class="form-control datepicker" placeholder="Select Time" v-model="scheduleTimeStart"></datepicker2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="schedule_mode == 'custom'">
                                <div class="row mt-2">
                                    <div class="col-12 text-center">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M0 16.4883V15.6805C0.000697637 15.1126 0.130381 14.5522 0.379263 14.0418C0.628146 13.5313 0.989726 13.084 1.43672 12.7336C3.38281 11.2079 4.99141 9.97036 14.2648 3.24067C15.5789 2.27817 18.1875 -0.0280793 20 -0.000735519C21.8125 -0.0280793 24.4219 2.27895 25.7352 3.23676C35.0078 9.96567 36.6187 11.2055 38.5633 12.7297C39.0108 13.0805 39.3727 13.5284 39.6216 14.0396C39.8705 14.5508 39.9999 15.1119 40 15.6805V16.1571C34.1547 20.5243 27.7898 25.2711 25.7352 26.7625C24.4211 27.7204 21.8125 30.0266 20 29.9993C18.1867 30.0258 15.5813 27.7219 14.2648 26.7625C12.4563 25.45 6.28906 21.0149 0 16.4883Z" fill="#6D7580"/>
                                            <path d="M40 19.2783V36.2502C40 37.2448 39.6049 38.1986 38.9016 38.9018C38.1984 39.6051 37.2446 40.0002 36.25 40.0002H3.75C2.75544 40.0002 1.80161 39.6051 1.09835 38.9018C0.395088 38.1986 0 37.2448 0 36.2502L0 19.5689C5.75859 23.7096 11.132 27.5791 12.7922 28.7877C14.375 29.9385 17.2078 32.5189 20 32.5002C22.7906 32.5189 25.625 29.94 27.2078 28.7838C29.0828 27.4221 34.5914 23.3174 40 19.2783Z" fill="#6D7580"/>
                                        </svg>
                                        <span class="d-block">I need custom schedule</span>
                                        <small class="d-block">Admin will contact you regarding your custom schedule</small>
                                    </div>
                                </div>
                                <div v-if="this.category != 'Physio'" class="row mt-5">
                                    <div class="col-12 text-center">
                                        <span class="text-action-primary" @click="schedule_mode = 'default'">Change to date selection</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 bottom-buttons">
                        <button class="btn btn-cancel" @click="goPrevious">Back</button>
                        <button v-if="schedule_mode == 'default'" class="btn btn-submit float-right" @click="goNext">Next</button>
                        <button v-else class="btn btn-submit float-right" @click="goNext">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="booking-modal modal fade" id="medicalSuppliesModal" tabindex="-1" role="dialog"
            aria-labelledby="medicalSuppliesModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <span class="modal-title d-block">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.5 6.75H14.9535L15.3285 5.25H6V3.75H16.29C16.404 3.75 16.5165 3.776 16.619 3.826C16.7214 3.87601 16.8111 3.94871 16.8813 4.03859C16.9514 4.12847 17.0001 4.23315 17.0237 4.34468C17.0473 4.45622 17.0452 4.57166 17.0175 4.68225L15.1425 12.1823C15.1019 12.3444 15.0082 12.4884 14.8764 12.5913C14.7446 12.6941 14.5822 12.75 14.415 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12V3H1.5V1.5H3.75C3.94891 1.5 4.13968 1.57902 4.28033 1.71967C4.42098 1.86032 4.5 2.05109 4.5 2.25V6.75ZM4.5 17.25C4.10217 17.25 3.72064 17.092 3.43934 16.8107C3.15804 16.5294 3 16.1478 3 15.75C3 15.3522 3.15804 14.9706 3.43934 14.6893C3.72064 14.408 4.10217 14.25 4.5 14.25C4.89782 14.25 5.27936 14.408 5.56066 14.6893C5.84196 14.9706 6 15.3522 6 15.75C6 16.1478 5.84196 16.5294 5.56066 16.8107C5.27936 17.092 4.89782 17.25 4.5 17.25ZM13.5 17.25C13.1022 17.25 12.7206 17.092 12.4393 16.8107C12.158 16.5294 12 16.1478 12 15.75C12 15.3522 12.158 14.9706 12.4393 14.6893C12.7206 14.408 13.1022 14.25 13.5 14.25C13.8978 14.25 14.2794 14.408 14.5607 14.6893C14.842 14.9706 15 15.3522 15 15.75C15 16.1478 14.842 16.5294 14.5607 16.8107C14.2794 17.092 13.8978 17.25 13.5 17.25Z" fill="white"/>
                            </svg> 
                            Medical supplies/Medication required
                        </span>
                        <small class="d-block">Optional</small>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button> -->
                        <div class="row mt-3">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="Search" v-model="keywordSearch" @keypress.enter="submitSearch()">
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div v-if="products && products.length > 0" class="container">
                            <div class="row text-center">
                                <div v-for="product in products" :key="product.id" class="col-4 mt-4">
                                    <div class="item-box">
                                        <img :src="getImageLink(product)" alt="image" class="d-block mb-2">
                                        <div class="title-price mb-2">
                                            <span class="product-title d-block prod-title mb-2">{{ product.title }}</span>
                                            <span class="product-price d-block prod-price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                        </div>
                                        <button class="btn btn-add-item" @click="addMedicalSuppliesItem(product)"><i class="fas fa-plus"></i> Add</button>
                                    </div>
                                </div>
                                <infinite-loading @infinite="infiniteHandler"></infinite-loading>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-cancel" data-dismiss="modal" @click="resetProductFilter()">Cancel</button>
                        <div class="float-right mt-3" v-if="medical_supplies_count > 0">
                            <span>Item added : <b><u>{{ medical_supplies_count }}</u></b></span>
                        </div>
                        <!-- <button type="button" class="btn btn-submit float-right" @click="resetProductFilter()">Add to Booking</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
import Datepicker from 'vuejs-datepicker'
import Datepicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading';

import API from './../../utils/API'
export default {
    props: [
        'category',
        'bookingServices', 
        'listFixedPackagePrice',
        'bookingAdditionalTreatments', 
        'bookingAssessments',
        'confinementServices',
        'confinementAddons',
        'type_of_services',
        'additional_treatments',
        'selected_confinement_services',
        'selected_confinement_addons',
        'gender_requirement',
        'language_requirement',
        'race_requirement',
        'assessments',
        'schedule_mode',
        'schedule_date_only'
    ],
    components: {
        ToggleButton,
        Datepicker,
        Datepicker2,
        InfiniteLoading
    },
    data(){
        return {
            selectedFixedPackagePrice: null,
            hasRequirement: false,
            scheduledate: null,
            scheduleTimeStart: null,
            scheduleTimeEnd: null,
            products: [],
            medical_supplies: [],
            medical_supplies_count: 0,
            paginateStart: 0,
            paginateLimit: 12,
            keywordSearch: null,
            loadEnded: false
        }
    },
    methods: {
        disabledDateValidation(_date){
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return _date < today || _date < new Date(today.getTime() + 2 * 24 * 3600 * 1000);
        },
        async refetchProducts(){
            this.loadEnded = false
            let res = null
            if(this.keywordSearch && this.keywordSearch.trim() != ''){
                res = await API.get(`/products?keywords=${this.keywordSearch}&_start=${this.paginateStart}&_limit=${this.paginateLimit}`)
            }else{ //if no keyword
                res = await API.get(`/products?_start=${this.paginateStart}&_limit=${this.paginateLimit}`)
            }
            
            if(res.data.products.length == 0) return this.loadEnded = true 

            if(this.products.length == 0) this.products = res.data.products
            else if(this.products.length > 0) this.products.push(...res.data.products)

            //next start plus 1
            this.paginateStart = this.paginateStart + this.paginateLimit
        },
        resetProductFilter(){
            this.paginateStart = 0
            this.paginateLimit = 12
            this.products = []
            this.keywordSearch = null
        },
        submitSearch(){
            this.products = []
            this.refetchProducts()
            // console.log('submit search weyhh')
        },
        getImageLink(_product) {
            if(!_product.variants[0].image || _product.variants[0].image.length == 0) return '/img/default_no_image.jpg'
            else return API.getAPIUrl() + _product.variants[0].image[0].url //get the first image
        },
        async infiniteHandler($state){
            await this.refetchProducts()
            if(!this.loadEnded) $state.loaded()
            else $state.complete()
        },
        addMedicalSuppliesItem(_product){
            const item = this.medical_supplies.find(i => i.item.id == _product.id)
            if(!item){
                this.medical_supplies.push({
                    item: _product,
                    quantity: 1
                })
            }else {
                item.quantity ++
            }

            //update count
            this.updateMedicalSuppliesItemsCount()
        },
        removeMedicalSuppliesItem(_product){
            const index = this.medical_supplies.findIndex(i => i.item.id == _product.item.id)
            this.medical_supplies.splice(index, 1)

            //update count
            this.updateMedicalSuppliesItemsCount()
        },
        updateMedicalSuppliesItemsCount(){
            let count = 0
            this.medical_supplies.forEach(product => {
                count += product.quantity
            })
            this.medical_supplies_count = count
        },
        updateDataToParent(){
            //combine the datetimes
            let startDateTime = moment(this.scheduledate + ' ' + this.scheduleTimeStart, 'YYYY-MM-DD hh:mm a')
            let endDateTime = moment(this.scheduledate + ' ' + this.scheduleTimeEnd, 'YYYY-MM-DD hh:mm a')

            let objData = {
                gender_requirement: this.gender_requirement,
                language_requirement: this.language_requirement,
                race_requirement: this.race_requirement,
                schedule_mode: this.schedule_mode,
                schedule_start_datetime: startDateTime,
                schedule_end_datetime: endDateTime,
                medical_supplies: this.medical_supplies,
                selectedFixedPackagePrice: this.selectedFixedPackagePrice,
                selected_confinement_services: this.selected_confinement_services,
                selected_confinement_addons: this.selected_confinement_addons
            }
            this.$emit('assessmentSection-data', objData)
        },
        selectConfinementService(_item){
            const indFound = this.selected_confinement_services.findIndex(i => i._id == _item._id)
            if(indFound !== -1) this.selected_confinement_services.splice(indFound, 1)
            else this.selected_confinement_services.push(_item)
        },
        selectConfinementAddon(_item){
            const indFound = this.selected_confinement_addons.findIndex(i => i._id == _item._id)
            if(indFound !== -1) this.selected_confinement_addons.splice(indFound, 1)
            else {
                _item.quantity = 1
                this.selected_confinement_addons.push(_item)
            }
        },
        changeConfinementAddonQuantity(_e, _item){
            let foundInd = (this.selected_confinement_addons.findIndex(i => i._id == _item._id))
            if(foundInd !== -1){
                this.selected_confinement_addons[foundInd].quantity = parseInt(_e.target.value)
            }
        },
        goNext(){
            this.updateDataToParent()
            this.$emit('go-to', 4)
        },
        goPrevious(){
            this.updateDataToParent()
            this.$emit('go-to', 2)
        }
    },
    mounted(){
        if(this.category == 'Physio') this.schedule_mode = 'custom'
    }
}
</script>

<style scoped>
.bg-form {
    /* background-color: #E5E5E5; */
}

/** Modal */
.booking-modal {

}
.booking-modal .modal-header {
    display: inline-block;
    background-color: #EB5757;
    color: #ffffff;
    padding: 30px 30px;
}
.booking-modal .modal-body {
    background-color: #F4F6F9;    
    height: 600px;
    overflow: auto;

}
.booking-modal .modal-body .item-box {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 12px 12px;
}
.booking-modal .modal-body .item-box img {
    width: 100%;
    padding: 5px;
}
.booking-modal .modal-body .title-price {
    height: 90px;
}
.booking-modal .modal-body .title-price .prod-title {
    font-weight: bold;
    color: #000000;
}
.booking-modal .modal-body .title-price .prod-price {
    font-weight: bold;
    color: #A91A18;
}
.booking-modal .modal-header .modal-title {
    font-size: 18px;
}
.booking-modal .modal-footer {
    display: inline-block;
    padding: 20px 30px;
}
/** Modal */


.top-title {
    text-align: center;
    background-color: #EB5757;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 0px;
}

.bottom-info {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #A91A18;
    margin: -5px 0px 0px 0px;
    padding: 15px 0px;
    border-radius: 8px;
    letter-spacing: 1px;
}
.bottom-info .text-not-yet {
    color: #EB5757;
}

.cards {

}

.card {
    border: unset;
    padding: 15px 40px;
}

.card .card-title small {
    display: block;
    font-size: 12px;
    color: #6D7580;
}

.card .card-body .item {
    padding: 15px 15px 10px 15px;
    border-bottom: 1px solid #DADEE3;
}
.card .card-body .item.no-bottom-border {
    border-bottom: unset;
}

.card .card-body .item small {
    display: block;
    margin-top: -3px;
    font-size: 11px;
    color: #3d3333;
    max-width: 90%;
}

.option-buttons {

}
.option-buttons .btn {
    padding: 10px 20px;
    font-size: 12px;
    color: #545D69;
    background-color: #DADEE3;
}
.option-buttons .btn.btn-left {
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
}
.option-buttons .btn.btn-right {
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
}
.option-buttons .btn.btn-active {
    color: #ffffff;
    background-color: #7CC6D6;
}

.btn.btn-add {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    background-color: #EBEEF2;
    border: 1px solid #DADEE3;
    border-radius: 6px;
    padding: 12px 25px;
}
.btn.btn-add-item {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #DADEE3;
    border-radius: 6px;
    padding: 15px;
    border: 1px solid #DADEE3;
    width: 100%;
}
.btn.btn-cancel {
    color: #ffffff;
    background-color: #858C94;
    padding: 15px 25px;
}
.btn.btn-submit {
    color: #ffffff;
    background-color: #7CC6D6;
    padding: 15px 100px;
}


.text-action-primary {
    color: #A91A18;
}
.text-action-primary:hover {
    text-decoration: underline;
    cursor: pointer;
}

/** Custom Checkbox start */
/* The container */
.custom-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox button */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox button */
.checkmark {
  position: absolute;
  top: 5px;
  right: 0px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #DADEE3;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox button is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #19AB4F;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-checkbox .checkmark:after {
 	top: 6px;
	left: 9px;
	width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/** Custom Checkbox end */

.custom-input-quantity {
    position: absolute;
    right: 45px;
    z-index: 999999;
}
.custom-input-quantity input[type=text] {
    margin-left: 10px;
    width: 53px;
    display: inline;
    border: unset;
    border-bottom: 1px solid gray;
    border-radius: 0px;
    padding: 0px 20px;
}


/** Change input stylings */
::-webkit-input-placeholder { /* Edge */
  color: #B3C2CD;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #B3C2CD;
}
::placeholder {
  color: #B3C2CD;
}

label {
    font-size: 13px;
}

select,
textarea,
input {
    font-size: 12px;
}
select { 
    height: 42px;
    padding: 0px 20px;
}
textarea,
input {
    font-size: 12px;
    padding: 20px 20px;
}
/* Change bootstrap input focus */
select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border: 2px solid #a91a18;
  box-shadow: unset;
  /* border-color: #a91a18; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #a91a18; */
  outline: 0 none;
}

/** Media Queries */
@media (min-width: 992px) {
    .sub-container {
        padding: 0px 150px;
    }
}
@media (max-width: 766px) {
    .card .card-body {
        padding: 0px;
    }
}
@media (max-width: 570px) {
    .bottom-info {
        font-size: 9px;
    }
    .bottom-buttons {
        height: 140px;
    }
}
@media (max-width: 500px) {
    .card {
        padding: 15px 10px;
    }

    .btn.btn-submit {
        width: 100%;
    }
    .btn.btn-cancel {
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>