<template>
    <div class="bg-form">
        <div class="container mb-5">
            <div class="sub-container">
                <div class="head-form mb-4">
                    <div class="top-title">
                        <span>Booking for</span>
                        <h4>{{ category }}</h4>
                    </div>
                    <div class="bottom-info row">
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Details</span>
                        </div>
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Assessment</span>
                        </div>
                        <div class="col-4 text-center">
                            <span><i class="fas fa-check-circle"></i> Summary</span>
                        </div>
                    </div>
                </div>
                <div class="cards">
                    <div class="card text-center notes">
                        <div class="text-center mb-4">
                            <svg width="38" height="46" viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M36 2H2C0.895 2 0 2.895 0 4V44C0 45.105 0.895 46 2 46H36C37.105 46 38 45.105 38 44V4C38 2.895 37.105 2 36 2Z" fill="#A67C52"/>
                                <path d="M32 41H6C5.448 41 5 40.552 5 40V8C5 7.448 5.448 7 6 7H32C32.552 7 33 7.448 33 8V40C33 40.552 32.552 41 32 41Z" fill="white"/>
                                <path d="M23 4C23 1.791 21.209 0 19 0C16.791 0 15 1.791 15 4H12C11.448 4 11 4.448 11 5V9C11 9.552 11.448 10 12 10H26C26.552 10 27 9.552 27 9V5C27 4.448 26.552 4 26 4H23Z" fill="#EFD358"/>
                                <path d="M19 5C19.5523 5 20 4.55228 20 4C20 3.44772 19.5523 3 19 3C18.4477 3 18 3.44772 18 4C18 4.55228 18.4477 5 19 5Z" fill="#B29930"/>
                                <path d="M17 30.0003C16.744 30.0003 16.488 29.9023 16.293 29.7073L12.293 25.7073C11.902 25.3163 11.902 24.6842 12.293 24.2933C12.684 23.9023 13.316 23.9023 13.707 24.2933L17 27.5863L24.293 20.2932C24.684 19.9022 25.316 19.9022 25.707 20.2932C26.098 20.6842 26.098 21.3163 25.707 21.7073L17.707 29.7073C17.512 29.9023 17.256 30.0003 17 30.0003Z" fill="#72C472"/>
                            </svg>
                        </div>
                        <div class="mb-3">
                            <h5>Thank You for contacting SELCARE Nursing Sdn Bhd</h5>
                        </div>
                        <div class="mb-3">
                            <p>
                                Your booking has been recorded and sent to our respective team.
                            </p>
                            <p>
                                We will contact you within <b>24 hours during operation hours</b> from the booking time.
                            </p>
                            <p>
                                <b>Monday-Friday: 8.30 am-5.30 pm</b>
                            </p>
                            <p>
                                Should you have any enquiries, please do not hesitate to contact us: <br />
                                <i class="fab fa-whatsapp"></i> <a href="tel:+60196661767" class="primary-red">+6 019 666 1767</a>. <br />
                                <i class="far fa-envelope"></i> <a href="mailto:info@selcarenursing.com" class="primary-red">info@selcarenursing.com</a>
                            </p>
                        </div>
                        <!-- <div>
                            <span class="text-big">Thank you for contacting Selcare services.</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['category']
}
</script>

<style scoped>
.bg-form {
    /* background-color: #E5E5E5; */
}

.container {
    height: calc(100vh - 200px);
}

.top-title {
    text-align: center;
    background-color: #EB5757;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 0px;
}

.bottom-info {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #A91A18;
    margin: -5px 0px 0px 0px;
    padding: 15px 0px;
    border-radius: 8px;
    letter-spacing: 1px;
}
.bottom-info .text-not-yet {
    color: #EB5757;
}

.cards {

}

.card {
    border: unset;
    padding: 15px 40px;
}

.card .card-body .contents {
    padding: 15px 35px 10px 35px;
    font-size: 15px;
}

.notes {
    font-size: 13px;
}

.div-separator {
    height: 2px;
    background-color: #EBEEF2;
}

.title-with-bar {
    padding: 15px 35px 10px 35px;
    background-color: #EBEEF2;
}
.title-with-green-bar {
    padding: 20px 35px 15px 35px;
    background-color: #7CC6D6;
}

span.text-big {
    font-size: 18px;
    font-weight: 500;
}

.btn.btn-add {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    background-color: #EBEEF2;
    border: 1px solid #DADEE3;
    border-radius: 6px;
    padding: 12px 25px;
}
.btn.btn-cancel {
    color: #ffffff;
    background-color: #858C94;
    padding: 15px 25px;
}
.btn.btn-submit {
    color: #ffffff;
    background-color: #7CC6D6;
    padding: 15px 100px;
}


/** Media Queries */
@media (min-width: 992px) {
    .sub-container {
        padding: 0px 150px;
    }
}
@media (max-width: 766px) {
    .card .card-body {
        padding: 0px;
    }
}
@media (max-width: 570px) {
    .bottom-info {
        font-size: 9px;
    }
    .bottom-buttons {
        height: 140px;
    }
}
@media (max-width: 500px) {
    .card {
        padding: 15px 10px;
    }

    .btn.btn-submit {
        width: 100%;
    }
    .btn.btn-cancel {
        width: 100%;
        margin-bottom: 10px;
    }
}


/** Others */
.font-weight-500 {
    font-weight: 500;
}
</style>